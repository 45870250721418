import React from "react";
import { NextArrow, CopySection } from "@molecules";
import loadable from "@loadable/component";
import { Container } from "@atoms";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));
const VideoPlayerInline = loadable(() =>
  import("@organisms/VideoPlayerInline")
);

const GrowingImageCopy = ({
  heading,
  subheading,
  subHeading,
  copy,
  video,
  image,
  smallerSpacer,
}) => {
  return (
    <GrowingImage heading={heading} image={image} smallerSpacer={smallerSpacer}>
      <Container frame>
        <CopySection
          twoColumns
          subHeading={subheading || subHeading}
          copy={copy}
          className="text-white"
        />
        <NextArrow className="mt-14" />
        {video && typeof window !== "undefined" && (
          <div className="mx-auto mt-14 max-w-2xl px-10 text-white sm:px-14 md:mt-24 lg:mt-32">
            <VideoPlayerInline url={video} />
          </div>
        )}
      </Container>
    </GrowingImage>
  );
};

export default GrowingImageCopy;
